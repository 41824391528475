import AbstractApplication from './Lib/AbstractApplication';
import HomeView            from './View/HomeView';

new class Home extends AbstractApplication {
    views () {
        return {
            '[data-page="home"]': HomeView,
        };
    }
}();
