const YOUTUBE_IFRAME_API = 'https://www.youtube.com/iframe_api';

export default class YouTubePlayer {
    constructor(el, options) {
        this.el = el;
        this.options = this.parseOptions(options);

        this.playerResolver = () => {};
        this.player = new Promise(resolve => {this.playerResolver = resolve;});

        this.onAPIReady();
        this.loadIFrameAPI();
    }

    parseOptions(options = {}) {
        const wrapEvents = ['onReady', 'onError'];

        options.events = options?.events || {};

        wrapEvents.forEach(name => {
            if (typeof options.events[name] !== 'function') {
                options.events[name] = () => {};
            }

            const targetFn = options.events[name];

            options.events[name] = function () {
                this[name].apply(this, arguments);
                targetFn.apply(null, arguments);
            }.bind(this);
        });

        return options;
    }

    api() {
        return this.player;
    }

    /**
     * Ready to use API
     */
    onAPIReady() {
        if (typeof window.onYouTubeIframeAPIReady === 'function') {
            return;
        }

        // initialize API player
        window.onYouTubeIframeAPIReady = () => new window.YT.Player(this.el, this.options);
    }

    loadIFrameAPI() {
        const tag = document.createElement('script');
        tag.src = YOUTUBE_IFRAME_API;

        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }

    onReady(event) {
        console.log(this.constructor.name + '::onReady', arguments);

        this.playerResolver(event.target);
    }

    onStateChange() {
        console.log(this.constructor.name + '::onStateChange');
    }

    onPlaybackQualityChange() {
        console.log(this.constructor.name + '::onPlaybackQualityChange');
    }

    onPlaybackRateChange() {
        console.log(this.constructor.name + '::onPlaybackRateChange');
    }

    onError() {
        console.error(this.constructor.name + '::onError', arguments);
    }

    onApiChange() {
        console.log(this.constructor.name + '::onApiChange');
    }
}
