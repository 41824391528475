import AbstractApi from './AbstractApi';
import YouTubePlayer from 'Lib/YouTubePlayer';

/**
 * @see https://developers.google.com/youtube/iframe_api_reference
 */
export default class YouTubeApi extends AbstractApi {
    constructor(el, src, options) {
        super(el, src, options);

        console.log(this.constructor.name + '::initialize', src);

        const playerFactory = new YouTubePlayer(this.el, {
            ...{
                videoId: this.src,
                host: 'https://www.youtube-nocookie.com',
                playerVars: {rel: 0, origin: window.location.host},
                events: {
                    'onReady': this.onReady.bind(this),
                    'onError': this.onError.bind(this),
                }
            },
            ...this.options,
        });

        this.player = playerFactory.api();
    }

    /**
     * @return Promise
     */
    play() {
        return this.player
            .then(player =>
                this.waitForReadyState(7)
                    .then(() => setTimeout(() => player.playVideo(), 100))
            );
    }

    /**
     * @return Promise
     */
    pause() {
        return this.player.then(player => player.pauseVideo());
    }

    /**
     * @param seconds
     * @return Promise
     */
    seek(seconds) {
        return this.player
            .then(player => Promise.resolve(player.getPlayerState())
                .then(state => {
                    if (state !== 1) {
                        this.play();
                    }

                    return state;
                })
                .then(() => player.seekTo(seconds, true))
            );
    }

    waitForReadyState(attempts) {
        return this.player
            .then(player => new Promise(resolve => {
                const intervalId = setInterval(() => {
                    const state = player.getPlayerState();

                    if (state === 1 || state === 5 || state === 2 || attempts <= 0) {
                        clearInterval(intervalId);
                        resolve();
                    }

                    attempts--;
                }, 300);
            }));
    }

    onReady(event) {
        console.log(this.constructor.name + '::onReady()', event);
    }

    onError(event) {
        console.log(this.constructor.name + '::onError()', event);
    }

    /**
     * Get YouTube video ID
     *
     * If URL has been provided then it will be parsed and extracted otherwise it will return input value
     *
     * @param {String} src
     */
    extract(src) {
        if (this.isValidUrl(src)) {
            /**
             * http://youtu.be/dQw4w9WgXcQ ...
             * http://www.youtube.com/embed/dQw4w9WgXcQ ...
             * http://www.youtube.com/watch?v=dQw4w9WgXcQ ...
             * http://www.youtube.com/?v=dQw4w9WgXcQ ...
             * http://www.youtube.com/v/dQw4w9WgXcQ ...
             * http://www.youtube.com/e/dQw4w9WgXcQ ...
             * http://www.youtube.com/user/username#p/u/11/dQw4w9WgXcQ ...
             * http://www.youtube.com/sandalsResorts#p/c/54B8C800269D7C1B/0/dQw4w9WgXcQ ...
             * http://www.youtube.com/watch?feature=player_embedded&v=dQw4w9WgXcQ ...
             * http://www.youtube.com/?feature=player_embedded&v=dQw4w9WgXcQ ...
             */
            let pattern = /(?:youtube(?:-nocookie)?\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/;

            const matches = src.match(pattern);

            if (matches === null || typeof matches[1] === 'undefined') {
                return '';
            }

            return matches[1];
        }

        return src;
    }
}
