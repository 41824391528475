import UrlAssert from '../Asserts/UrlAssert';

export default class AbstractApi {
    /**
     * @param {HTMLElement} el  - where to bind
     * @param {string} src      - ID, Link or something that can identify what should be played
     * @param {Object} options  - player option
     */
    constructor ( el, src, options ) {
        this.el = el;
        this.src = this.extract(src);
        this.options = options;
    }

    play () {
    }

    pause () {
    }

    /**
     * Get target ID for API usage
     *
     * @param {String} src
     */
    extract ( src ) {
        return src;
    }

    /**
     * @param {String} url
     * @return {boolean}
     */
    isValidUrl ( url ) {
        const assert = new UrlAssert();
        const errors = assert.validate(url);

        return errors.length === 0;
    }
}
