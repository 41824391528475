import AbstractView from './AbstractView';
import {Carousel} from '@cryengine/styleguide';
import YouTubeModal from './Modal/YouTubeModal';

// require('../../css/block/carousel.scss');

const SELECTOR_STORIES = '[data-bind="stories-carousel"]';

export default class HomeView extends AbstractView {
    events () {
        return {
            'click [data-bind="hero-video--trigger"]': 'showYouTubeVideo',
        };
    }

    initialize () {
        console.log(this.constructor.name + '::initialize');

        const storiesEl = this.el.querySelector(SELECTOR_STORIES);
        if (storiesEl) {
            this.stories = new Carousel(storiesEl, {
                pageDots: false,
                wrapAround: true,
            });
        }

        this.youtube = new YouTubeModal({
            el: this.el.querySelector('[data-bind="youtube-modal"]'),
        });
    }

    showYouTubeVideo () {
        this.youtube.show();
    }
}
