import AbstractModal from './AbstractModal';
import YouTubeApi    from '../../Lib/EmbedApi/YouTubeApi';

const SELECTOR_YOUTUBE_EMBED = '[data-bind="youtube-embed"]';
const CLASS_OPEN = 'lightbox--open';

export default class YouTubeModal extends AbstractModal {
    events() {
        return {
            'click [data-bind="modal-close"]': 'hide',
            'click': 'hide'
        };
    }

    initialize ( options ) {
        super.initialize(options);

        this.youtube = undefined;
    }

    async show () {
        if (!this.youtube) {
            const youtubeEl = this.el.querySelector(SELECTOR_YOUTUBE_EMBED);
            const src = youtubeEl.dataset.src;

            this.youtube = new YouTubeApi(youtubeEl, src);
        }

        this.lockBody();
        this.el.classList.add(CLASS_OPEN);

        if (this.youtube) {
            this.youtube.play();
        }
    }

    async hide () {
        if (this.youtube) {
            this.youtube.pause();
        }
        this.el.classList.remove(CLASS_OPEN);
        this.unlockBody();
    }
}
